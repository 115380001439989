// $platform-name: 'lifelinedarlingdownsandsouthwestqueenslandltd';

//
// Environment
//

//
// Config values
//
$platform-name: "lifelinedarlingdownsandsouthwestqueenslandltd";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #003575;
$brand-secondary: #7f9ed2;
$grey: #222;
$darkblue: #011d40;
$donate-colour: #f5a417;
$bg: #ece9e9;
// Create greyscale

// Utility colours

//
// Layout
//

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
$site-bleed: 20px;
$container-max-width: 1230px;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "logo.svg";
$logo-width: 270px;
$logo-height: 90px;

// $logo-absolute: true;
//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Open Sans", sans-serif;
$headings-colour: $grey;
$font-weight-bold: 600;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;
// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1em;
$btn-padding-y: 0.75em;
$btn-font-family: $font-open-sans;
$btn-font-weight: $font-weight-bold;
// Donate button
$donate-btn-colour: white;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: #989898;
$social-icons-colour: #fff;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: $grey;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
$card-heading-font-size: $font-size-h5;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//

// Header content
$header-content-margin-top: $spacer;
$header-content-margin-bottom: 0.5rem;

// Tagline
$tagline-enabled: true;
$tagline-colour: $brand-primary;
$tagline-font-weight: 900;
$tagline-font-size: $font-size-h4;
// Main call to action
$cta-btn-gap: 10px;

// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
$header-search-button-background-colour: #989898;
$header-search-button-icon-colour: #fff;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons
$header-social-icons-margin-right: 10px;

//
// Sticky header
//
$header-sticky-enabled: true;
$header-sticky-background-colour: white;
$header-sticky-header-content-margin-top: 0.25rem;
$header-sticky-header-content-margin-bottom: 0.25rem;

//
// Navigation
//
$nav-background-colour: transparent;
$nav-normal-align-items: right;
$nav-normal-align-items: center;
$nav-top-level-item-padding: 15px 20px;
// Top level items

// Submenus

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0rem;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-heading-font-size: 3rem;
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(
  to right,
  rgba(0, 0, 0, 0.7) 0%,
  rgba(0, 0, 0, 0) 50%
);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 600px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-read-more-enabled: false;
// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1.15rem;
$home-intro-padding-y: $spacer * 1;
$home-intro-margin-top: 4rem;
//
// Home features
//
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 4;
//
// Impact stats
//
$impact-stats-background-image: "impact.svg";
$impact-stats-padding-y: $spacer * 2;
$impact-stats-background-image-opacity: 0.3;
$impact-stats-background-colour: $darkblue;
// Heading

// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: $bg;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//

$footer-background-colour: $brand-primary;
$footer-prefab: 4;
$footer-link-colour: #fff;

// Footer admin links

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: white;
$newsletter-heading-font-size: 1.5em;
$newsletter-heading-text-align: left; // left, center, right

$newsletter-button-background-colour: $brand-secondary;
$newsletter-heading-margin-bottom: $spacer;
$newsletter-button-colour: white;

// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//

//
// Home quick giving panel
//
$home-quick-giving-donation-amount-background-colour: $brand-primary;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-heading-font-size: $font-size-h4;
$home-quick-giving-donation-amount-figure-colour: white;

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite logo

// Subsite nav

//
// Cookie consent
//


// Subsite logo
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: false;
$subsite-hide-header-search: false;
$subsite-hide-main-cta: false;
$subsite-content-max-width: $container-max-width;
$subsite-logo: 'title';

// Subsite nav
$subsite-nav-breakpoint: map-get($breakpoints, lg);
$subsite-nav-max-width: 100%;
$subsite-nav-margin-top: 0;
$subsite-nav-margin-bottom: $spacer;
$subsite-nav-background-colour: $brand-primary;
$subsite-nav-contents-max-width: $container-max-width;
$subsite-nav-align-items: center;
$subsite-nav-top-level-item-padding: 12.5px 20px;
$subsite-nav-top-level-item-colour: text-contrast($brand-primary);
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family !default;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size !default;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight !default;
$subsite-nav-top-level-item-background-colour: transparent;
$subsite-nav-top-level-item-hover-colour: $subsite-nav-top-level-item-colour;
$subsite-nav-top-level-item-hover-background-colour: rgba(255, 255, 255, 0.075);
