//nav
.menuMain .topLevel {
  border-top: solid 1px #dadada;
}

//tagline
.tagline {
  font-size: 1em;
  margin-right: 1.5rem;
  text-align: right;
  span {
    color: $donate-colour;
    font-weight: 600;
  }
}

//intro
.homeIntro p {
  font-weight: normal;
}

//quick
// .homefeaturecategory-homeboxquickgiving {
//   margin-top: -3rem;
//   border-radius: 5px;
// }
// .homefeaturecategory-homeboxquickgiving {
//   border-radius: 5px;
//   overflow: hidden;
//   // max-width: $container-max-width;
//   :before {
//     background-position: center 5%;
//   }
//   @media (max-width: map-get($breakpoints, xl)) {
//     width: auto;
//     margin-left: 1rem;
//     margin-right: 1rem;
//   }
// }

//homefeature
.homeFeatures
  .homeFeature
  [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
.homeFeatures .homeFeature [class^="listed"][class$="Text"] > p {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

//footer
.pageFooter {
  h1,
  h4 {
    letter-spacing: 1px;
  }
  .footerBox {
    p {
      font-size: 0.75em;
    }
  }
  .footerBox.footerBox1 {
    img {
      width: 150px;
      height: auto;
    }
  }
  .footerBox.footerBox2 {
    ul li {
      font-size: 0.75em;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .footerBox.footerBox3 {
    label {
      font-size: 0.75em;
    }
  }
  .footerBox.footerBox4 {
    position: relative;
    text-align: center;
    padding-top: $spacer * 2;
    .socialIcons {
      justify-content: center;
      padding-top: 20px;
    }
    p {
      margin-top: $spacer;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(white, 0.1);
    }
  }
}

.pageHeader.sticky .menuMain {
  display: none;
}

// Header tagline - Layout
.tagline {
  margin-right: $header-social-icons-margin-right;
}

// Sticky header - Content align
.pageHeader.sticky .headerContent .mainCallToAction {
  flex-grow: 0;
}

// Subsite - Mental Health Hub
body.subsite .contentContainer {

  // Items order
  .headerWrapper.headerWrapperSubsite { order: 1; }
  .subsiteBody {
    order: 4;
    padding-top: 0;
  }

  // Subsite title
  .headerTextSubsite {
    order: 2;
    position: relative;
    padding-bottom: 1.5rem;
    background-color: $brand-primary;
    .subsiteTitle {
      color: text-contrast($brand-primary);
    }
    &:before {
      content: '';
      position: absolute;
      background-color: $brand-primary;
      width: calc(100vw + .5px);
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  // kill carousel border radius
  .carouselSlide { border-radius: 0; }

  // Overal feeds layout
  .subsiteFeeds {
    flex-direction: column;
  }

  // Individual feed layout
  .subsiteFeeds .homeFeed {
    padding-top: 0;
    padding-bottom: $spacer;
    margin: 0;
    .feedList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  // Sponsor logos feed
  .subsiteFeeds .feedItem[class*='ogos'] {
    width: 100%;
    pointer-events: none;
    .feedItemDetailsWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        display: block;
        max-width: 180px;
        max-height: 110px;
        margin: $spacer;
      }
      @media (max-width: map-get($breakpoints, md)) {
        padding: $spacer;
        img {
          max-width: 95px;
          max-height: 90px;
        }
      }
    } 
  }
}

// Kill things on subsite inner pages
body.subsite.subsiteInner .post {
  .headerTextSubsite,
  .contentBlock .postAside {
    display: none;
  }
}
